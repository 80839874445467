<template>
  <el-container>
    <el-header height="220">
      <el-row style="margin-top: 10px">
        <el-select
          v-model="queryInfo.scope"
          clearable
          placeholder="查询条件"
          style="margin-left: 5px"
          @change="onSelectChange"
        />
        <el-button type="plain" icon="el-icon-plus" style="margin-left: 5px" @click="publishVideoDiaglog = true">发布商品</el-button>
      </el-row>
    </el-header>
    <el-main>
      <el-table
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          fixed="left"
          label="No"
          type="index"
        />
        <el-table-column
          prop="createTime"
          label="发布时间"
          width="150"
        />
        <el-table-column
          prop="picUrl"
          label="封面"
          width="90"
        >
          <template slot-scope="scope">
            <el-image :src="scope.row.picUrl" min-width="30" height="20" />
          </template>
        </el-table-column>
        <el-table-column
          prop="itemId"
          label="商品 ID"
        >
          <template slot-scope="scope">
            <router-link style="text-decoration-line: none" target="_blank" :to="`/mall/item?id=${scope.row.itemId}`">
              <span>{{ scope.row.itemId }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="商品名"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.title"
              :content="scope.row.title"
              raw-content
              placement="top-start"
            >
              <span v-if="scope.row.title.length <= 15">
                {{ scope.row.title }}
              </span>
              <span v-else>
                {{ scope.row.title.substr(0, 15) + "..." }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="价格"
        />
        <el-table-column
          prop="stock"
          label="库存"
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="280"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handlePreview(scope.$index, scope.row)"
            >预览</el-button>
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :small="screenWidth <= 768"
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>

    <!-- 发布视频稿件对话框 -->
    <el-dialog
      :visible.sync="publishVideoDiaglog"
      :before-close="handleClose"
      width="70%"
      center
    >
      <my-product-publish @post-publish="onPostPublish"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="publishVideoDiaglog = false">取 消</el-button>
        <el-button type="primary" @click="publishVideoDiaglog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 修改视频可见范围对话框 -->
    <el-dialog
      append-to-body
      :visible.sync="showEditScopeDialog"
      width="30%"
      center
    >
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>修改视频可见范围</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateScope">更新</el-button>
        </div>
        <div class="text item">
          <el-select v-model="form.scope" placeholder="选择可见范围">
            <el-option label="本人可见" value="1" />
            <el-option label="所有人可见" value="2" />
            <el-option label="VIP 可见" value="3" />
            <el-option label="验证码可见" value="4" />
          </el-select>
        </div>
      </el-card>
    </el-dialog>
    <!-- 视频预览对话框 -->
    <el-dialog
      title="预览视频"
      append-to-body
      :visible.sync="showPreviewDialog"
      :before-close="handleDialogClose"
      width="70%"
      center
    >
      <template>
        <video-preview-player :video-prop.sync="videoProp" />
      </template>
    </el-dialog>
  </el-container>
</template>

<script>
import VideoPreviewPlayer from 'components/VideoPreviewPlayer'
import MyProductPublish from '@/views/mall/MyProductPublish'
import { updateVideoScope, videoInfo, deleteVideoPost, getVideoPosts } from '@/api/video'
import { getProducts } from '@/api/mall'

export default {
  name: 'MyProduct',
  components: { VideoPreviewPlayer, MyProductPublish },
  data() {
    return {
      queryInfo: {
        scope: null,
        pn: 1
      },
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      // **********************************************************************
      videoProp: null,
      showVideoResourceDialog: false,
      showEditScopeDialog: false,
      showPreviewDialog: false,
      form: {
        title: null,
        price: 9.99,
        coverFileId: null
      },
      videoResources: [],
      publishVideoDiaglog: false
    }
  },
  created() {
    document.title = '商品管理'
    this.getData()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.getData()
      // 回到顶部
      scrollTo(0, 0)
    },
    getData() {
      this.dataList = []
      getProducts(this.currentPage).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    handleScope(index, row) {
      this.form.videoId = row.videoId
      this.form.scope = '' + row.scope
      this.showEditScopeDialog = true
    },
    handleDialogClose(done) {
      this.showPreviewDialog = false
      this.videoProp = {
        videoId: null,
        play: false
      }
      done()
    },
    handlePreview(index, row) {
      videoInfo(row.videoId).then(res => {
        if (res.code === 0) {
          this.showPreviewDialog = true
          this.videoProp = {
            videoId: res.data.videoId,
            play: true
          }
        }
      })
    },
    handleEdit(index, row) {
      const path = '/post/video/edit/' + row.videoId
      this.$router.push(path)
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除 ' + row.title + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteVideoPost(row.videoId).then(res => {
          if (res.code === 0) {
            this.$notify({
              title: '提示',
              message: '稿件已删除',
              type: 'warning',
              duration: 3000
            })
            this.$router.go(0)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    onUpdateScope() {
      this.showEditScopeDialog = false
      updateVideoScope(this.form).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '视频可见范围已更新',
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    onSelectChange() {
      this.$message.info(this.queryInfo)
    },
    handleClose() {
    },
    onPostPublish() {
      this.publishVideoDiaglog = false
    }
  }
}
</script>

<style>
</style>
