<template>
  <el-row class="movie-list">
    <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>上传商品封面</span>
          </div>
          <div class="text item">
            <el-tooltip class="item" effect="dark" content="点击上传图片" placement="top-end">
              <el-upload
                class="avatar-uploader"
                :action="imgOssUrl"
                :headers="imgHeaders"
                :data="imgData"
                :with-credentials="false"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError"
                :on-change="handleOnChange"
              >
                <img v-if="coverUrl" :src="coverUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-tooltip>
          </div>
        </el-card>
      </el-row>
    </el-col>
    <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>商品信息</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onSubmit">发布</el-button>
          </div>
          <div class="text item">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="商品名">
                <el-input v-model="form.title" style="padding-right: 1px" placeholder="商品名不能超过 50 个字符" />
              </el-form-item>
              <el-form-item label="价格">
                <el-input-number v-model="form.price" :min="1" :max="1000" style="margin-left: 5px" />
              </el-form-item>
              <el-form-item label="库存数量">
                <el-input-number v-model="form.amount" :min="1" :max="10000" style="margin-left: 5px" />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { getVideoCoverChannelInfo } from '@/api/file'
import { addProduct } from '@/api/mall'

export default {
  name: 'MyProductPublish',
  data() {
    return {
      // ****************************************************************************************************************
      imgOssUrl: '',
      imgHeaders: {
        Authorization: ''
      },
      imgData: {
        channelCode: null
      },
      coverUrl: null,
      // ****************************************************************************************************************
      form: {
        coverFileId: null,
        coverChannelId: 0,
        title: null,
        price: null,
        amount: null
      }
    }
  },
  created() {
    getVideoCoverChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.form.coverChannelId = resData.channelCode
        this.imgData.channelCode = resData.channelCode
        this.imgOssUrl = resData.ossUrl
        this.imgHeaders.Authorization = 'Bearer ' + resData.token
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传视频封面',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: '获取 OSS 服务器地址失败, 暂时无法上传视频封面',
        type: 'warning',
        duration: 3000
      })
    })
  },
  mounted() {
  },
  methods: {
    // ****************************************************************************************************************
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG) {
        this.$message.error('封面图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('封面图片大小不能超过 10MB!')
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        const resData = res.data
        this.coverUrl = URL.createObjectURL(file.raw)
        this.form.coverFileId = resData.uploadId
      } else {
        this.$notify({
          title: '提示',
          message: '视频封面上传失败，请重试！' + res.msg,
          type: 'warning',
          duration: 3000
        })
      }
    },
    handleAvatarError(error, file) {
      this.$notify({
        title: '提示',
        message: '视频封面上传失败，请重试！' + error,
        type: 'warning',
        duration: 3000
      })
    },
    handleOnChange(file, fileList) {
    },
    // ****************************************************************************************************************
    onSubmit() {
      if (!this.form.coverFileId) {
        this.$notify({
          title: '提示',
          message: '你还没有上传视频封面',
          type: 'warning',
          duration: 3000
        }
        )
        return
      }

      addProduct(this.form).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '商品已发布',
            type: 'warning',
            duration: 3000
          })
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })

      this.$emit('post-publish', this.form.coverFileId)
    }
  }
}
</script>

<style>
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 240px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 320px;
  height: 240px;
  display: block;
}
</style>
