<template>
  <div id="dplayer" ref="dplayer" style="height: 480px;" />
</template>

<script>
import { videoUrl } from '@/api/video'

import flvjs from 'flv.js'
import DPlayer from 'dplayer'

export default {
  name: 'VideoPreviewPlayer',
  props: {
    videoProp: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      flvjs,
      DPlayer,
      message: '',
      dplayer: null
    }
  },
  watch: {
    // 监控 videoProp 对象的变化
    videoProp(newVal) {
      console.log('videoProp changed')
      if (!newVal.play) {
        this.dplayer.destroy()
      } else {
        this.getVideoUrl(newVal.videoId)
      }
    }
  },
  created() {
    this.getVideoUrl(this.videoProp.videoId)
  },
  methods: {
    getVideoUrl(videoId) {
      videoUrl(videoId).then(res => {
        if (res.code === 0) {
          const urlType = res.data.type
          if (urlType === 'mp4') {
            const urls = res.data.urls
            for (const url of urls) {
              url.type = 'normal'
            }
            this.dplayer = this.initMp4Player(this.videoProp.coverUrl, urls)
          } else if (urlType === 'flv') {
            const urls = res.data.urls
            const url = urls[0].url
            this.dplayer = this.initFlvPlayer(this.videoProp.coverUrl, url)
          } else {
            this.message = 'url 类型无法识别'
          }
        } else {
          console.error(res.msg)
        }
      }).catch(error => {
        console.error(error.message)
      })
    },
    initMp4Player(coverUrl, videoUrls) {
      return new DPlayer({
        container: document.querySelector('#dplayer'),
        lang: 'zh-cn',
        screenshot: false,
        autoplay: false,
        volume: 0.1,
        mutex: true,
        video: {
          pic: coverUrl,
          defaultQuality: 0,
          quality: videoUrls
        }
      })
    },
    initFlvPlayer(coverUrl, videoUrl) {
      return new DPlayer({
        container: document.getElementById('dplayer'),
        video: {
          url: videoUrl,
          type: 'customFlv',
          customType: {
            customFlv: function(video, player) {
              const flvPlayer = flvjs.createPlayer({
                type: 'flv',
                url: video.src
              })
              flvPlayer.attachMediaElement(video)
              flvPlayer.load()
            }
          }
        }
      })
    }
  }
}
</script>

<style>
</style>
